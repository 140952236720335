<template>
<div class=" mx-auto">
        <div>
            <Loading :isLoading= "this.isLoading" />
            <div>
                <form @submit="Next" class="md:px-8 pt-6 pb-8 mb-4" style="text-align: left;">
                    <br>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <div class="text-center">
                                <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl" style="color: #82385b; font-weight: bold; margin-top:0px;">
                                    Información Laboral</p>
                            </div>
                        </div>
                        <h4 class="text-base lg:text-base xl:text-base mt-5 text-center">Paso 4 de 10</h4>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                        <div class="row">
                            <div class="text-center">
                                <!-- <p v-if="errors.length">
                                <ul>
                                    <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                                </ul>
                                </p> -->
                                <br>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                      <div class="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lx:grid-cols-2">
                        <div class="border">
                          <div class="center">
                              <div class="row" style="margin-top:5.5px">
                                <div class="row" style="margin-left:5px">
                                  <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                    <label style="text-align: left;font-size:15px;color:black;color: #6E6E6E;">Estatus:</label>
                                    <div class="input-group" style="margin-top:-5px;">
                                      <input type="radio" name="rdbStatus" value="ACTIVO" v-model="status" v-on:change="changeStatus(), is_retired = true, is_student = false" required style="margin-left:30px;" /> Activo(a)
                                      <input type="radio" name="rdbStatus" value="JUBILADO" v-model="status" v-on:change="changeStatus(), is_retired = false, is_student = false" style="margin-left:20px"/> Jubilado(a)
                                      <input type="radio" name="rdbStatus" value="ESTUDIANTE" v-model="status" v-on:change="changeStatus(), is_retired = false, is_student = true" style="margin-left:20px"/> Estudiante
                                    </div>
                                  </div>
                                </div>
                                  <div class="w-full mb-10 mt-5" v-if="is_retired">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          <label class="label-input">Ocupación principal (Fuente principal de ingreso):</label> <br />
                                          <input type="radio" name="rdbmainOccupation" value="Empleado" v-model="mainOccupation" v-on:change="getCatalogOccupation()" required style="margin-left:30px;"/> Empleado
                                          <input type="radio" name="rdbmainOccupation" value="Negocio Propio" v-model="mainOccupation" v-on:change="nameWork = '', getCatalogOccupation()" style="margin-left:20px"/> Negocio Propio <br>
                                          <input type="radio" name="rdbmainOccupation" value="Trabajador de la educacion" v-model="mainOccupation" v-on:change="getCatalogOccupation(), nameWork = ''" style="margin-left:30px"/> Trabajador de la educacion <br>
                                      </div>
                                  </div>
                                  <div class="w-full mt-5" v-if="is_retired == true && mainOccupation == 'Empleado'">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                        <label class="label-input">Nombre Centro de Trabajo:</label>
                                        <select lang="en" translate="no" ref="workplaceSelected" required
                                          v-on:change="changeNameWork()"
                                          v-model="nameWork" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor">
                                            <option value="GRUPO CABSA">GRUPO CABSA</option>
                                            <option value="OTRO">OTRO</option>
                                        </select>
                                      </div>
                                  </div>
                                  <div class="w-full mt-5" v-if="is_retired">
                                    <div class="mt-5 text-left mr-10" v-if="mainOccupation == 'Negocio Propio' || nameWork == 'OTRO'">
                                        <label class="label-input">Razón Social de la empresa que genera su principal fuente de ingresos:</label>
                                        <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                        minlength="3" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚíÍ ]{3,50}" v-model="businessName" required>
                                    </div>
                                  </div>
                                  <div class="w-full mt-5" v-if="is_retired && mainOccupation != 'Trabajador de la educacion'">
                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                      <label class="label-input">Clave/Número de empleado:</label>
                                      <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="presupustalCode"
                                      minlength="4" maxlength="20" @keyup="validateOnlyLettersAndNumbers('presupustalCode', false)" v-model="presupustalCode" required>
                                    </div>
                                  </div>
                                  <div class="w-full mt-5" v-if="is_retired && mainOccupation == 'Trabajador de la educacion'">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          <label class="label-input">Nombre Centro de Trabajo:</label>
                                          <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                        minlength="3" maxlength="50" pattern="[a-zA-Z-0-9íéñúóáÍÑÁÉÓÚ# ]{3,50}" v-model="nameWork" required>
                                      </div>
                                      <div class="text-center">
                                        <label class="label-input">{{this.nameWork}}</label>
                                    </div>
                                  </div>
                                  <div class="w-full mt-5" v-if="is_retired == true && mainOccupation == 'Trabajador de la educacion'">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                        <label class="label-input">Categoria:</label>
                                        <select lang="en" translate="no" ref="stateSelected" required v-model="category" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor">
                                            <option value="PREESCOLAR">Preescolar</option>
                                            <option value="PRIMARIA">Primaria</option>
                                            <option value="SECUNDARIA">Secundaria</option>
                                            <option value="MEDIA SUPERIOR">Media superior</option>
                                            <option value="SUPERIOR">Superior</option>
                                            <option value="EDUCACIÓN ESPECIAL">Educación especial</option>
                                            <option value="INDIGENA">Indigena</option>
                                            <option value="PROGRAMAS COMPENSATORIOS">Programas compensatorios</option>
                                            <option value="EDUCACION PARA ADULTOS">Educación para adultos</option>
                                            <option value="EDUCACION TECNOLOGICA">Educación tecnológica</option>
                                            <option value="EDUCACION NORMAL">Educación normal</option>
                                        </select>
                                      </div>
                                  </div>


                                  <div class="w-full mt-5" v-if="is_retired == true && mainOccupation == 'Trabajador de la educacion' || is_retired == true && mainOccupation == 'Empleado' && nameWork != 'OTRO'">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          <label class="label-input">Puesto/Ocupación:</label>
                                          <select class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="selectoccupation" ref="occupationSelected" required v-model="occupation"
                                          >
                                            <option v-for="element in occupation_list" v-bind:value="addUpperCase(element.occupation)" :key="element.occupation">{{ addUpperCase(element.occupation) }}</option>
                                          </select>
                                      </div>
                                  </div>
                                  
                                  <div class="w-full mt-5" v-if="is_retired == true && mainOccupation == 'Trabajador de la educacion' && nameWork == 'OTRO' || is_retired == true && mainOccupation == 'Empleado' && nameWork == 'OTRO'">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          <label class="label-input">Puesto/Ocupación:</label>
                                          <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                            @keyup="validateSpecialCharacters('occupation')"
                                            minlength="4" maxlength="50" 
                                            pattern="[a-zA-Z-0-9íéñúóáÍÑÁÉÓÚ# ]{4,50}" v-model="occupation" required>
                                      </div>
                                  </div>
                                  <div class="w-full mt-5" v-if="is_retired == true && mainOccupation == 'Empleado'">
                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                        <label class="label-input">Nombre del jefe inmediato:</label>
                                        <input type="text" id="supervisor" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                        minlength="4" maxlength="50" @keyup="validateOnlyLetters('supervisor', true)" v-model="supervisor" required>
                                    </div>
                                  </div>
                              </div>
                              <div class="row" style="margin-top:5.5px" v-if="is_retired">
                                <div class="w-full mt-5">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          <label class="label-input">Teléfono:</label>
                                          <input class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" id="cellphone"
                                          minlength="12" maxlength="12"  min="0" v-model="cellphone" v-maska="'### ###-####'" @keyup="validateNumberPhone()">
                                      </div>
                                  </div>
                                  <div class="w-full mt-5">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          <label class="label-input">Extensión:</label>
                                          <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                              onKeyPress="if(this.value.length==3) return false;" oninput="validity.valid||(value='');"
                                              pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ ]{0,3}" min="0" v-model="phoneExt">
                                      </div>
                                  </div>
                                  <div class="w-full mt-5">
                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;width:220px">
                                        <label class="label-input">Fecha de Ingreso:</label>
                                        <!-- <input type="text" id="datepickerAdmissionDate" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="calculateNumberEntryTwoDates()"
                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="admissionDate" required placeholder="Seleccionar fecha"> -->
                                        <date-picker
                                            simple
                                            color="#810042"
                                            placeholder="Fecha ingreso"
                                            locale="es"
                                            input-format="DD/MM/YYYY"
                                            @change="selectDate($event,1)"
                                            v-model="admissionDate"
                                            id="admissionDate"
                                            :localeConfig="{
                                              es: {
                                                displayFormat: 'DD/MM/YYYY',
                                                  lang: {
                                                    year: 'Año',
                                                    month: 'Mes',
                                                    day: 'Día',
                                                    label: '',
                                                    submit: 'Seleccionar',
                                                    cancel: 'Cancelar',
                                                    now: 'Hoy',
                                                    nextMonth: 'Siguiente',
                                                    prevMonth: 'Anterior',
                                                  },
                                                }
                                            }" />
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                            <div class="row" style="margin-left:24px; margin-top:0px;margin-right:10px">
                                <div class="row" style="margin-top:5.5px">
                              <div class="w-full mt-5" v-if="is_retired" >
                                  <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                      <label class="label-input">Código Postal:</label>
                                      <input type="number" id="codigoPostal"
                                      class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" oninput="validity.valid||(value='');" onKeyPress="if(this.value.length==5) return false;" @keyup="validateCP($event)"
                                      minlength="5" maxlength="5" v-on:blur="validateCP()" min="0" v-model="postalCode" required pattern="[0-9]{5,5}">
                                  </div>
                              </div>
                              <div class="w-full mt-5" v-if="is_retired">
                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                        <label class="label-input">Número exterior:</label>
                                        <input type="number" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" required
                                        onKeyPress="if(this.value.length==5) return false;" min="0" oninput="validity.valid||(value='');" v-model="numberExt">
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top:5.5px">
                            <!-- <div class="center">
                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:5px;width:220px">
                                        <label class="label-input">Fecha de Ingreso:</label>
                                        <input type="text" style="border:1px solid #82385b; text-transform: capitalize;margin-top:-0.5px;widght:120px; 
                                        border:1px solid #82385b;margin-top:-5px; border-top-left-radius:5px; border-top-right-radius:5px;
                                        border-bottom-right-radius:5px;border-bottom-left-radius:5px;" id="datepickerAdmissionDate" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="calculateNumberEntryTwoDates()"
                                        minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="admissionDate" required placeholder="Seleccionar fecha">
                                    </div>
                                </div> -->
                            </div>
                            <div class="mt-5 text-left mr-10" v-if="is_retired">
                                <label class="label-input">Calle:</label>
                                <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                minlength="4" maxlength="50" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚíÍ ]{4,50}" v-model="street" required>
                            </div>
                            <div class="mt-5 text-left mr-10" v-if="is_retired">
                                <label class="label-input">Colonia:</label>
                                <select lang="en" translate="no" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" ref="stateSelected" required v-model="colony">
                                    <option v-for="colony in list_colonys" v-bind:value="addUpperCase(colony.nombre)" :key="colony.nombre">{{ addUpperCase(colony.nombre) }}</option>
                                </select>
                            </div>
                              <div class="mt-5 text-left mr-10" v-if="is_retired">
                                  <div class="w-full mt-5">
                                      <div class="form-group">
                                          <label class="label-input">Municipio:</label><br>
                                          <label style="font-size:20px;color: #6E6E6E;text-transform: uppercase;">{{this.municipality}}</label>
                                      </div>
                                  </div>
                                  <div class="w-full mt-5">
                                      <div class="form-group">
                                          <label class="label-input">Ciudad:</label><br>
                                          <label style="font-size:20px;color:black;color: #6E6E6E;text-transform: uppercase;">{{this.city}}</label>
                                      </div>
                                  </div>
                              </div>
                              <div class="mt-5 text-left mr-10" v-if="is_retired">
                                  <div class="w-full mt-5">
                                      <div class="form-group">
                                          <label class="label-input">Estado:</label><br>
                                          <label style="font-size:20px;color:black;color: #6E6E6E;text-transform: uppercase;">{{this.state}}</label>
                                      </div>
                                  </div>
                                  <div class="w-full mt-5">
                                  </div>
                              </div>
                              
                              <div class="row" style="margin-left:5px">
                                <div>
                                  <div class="form-group">
                                      <label class="label-input">¿Usted ha tenido cargo público?</label> <br />
                                      <input type="radio" name="rdbHasHadPublicPosition" value="1" v-model="hasHadPublicPosition" required style="margin-left:30px;"/> 
                                      <label class="label-input-radio">SI</label>
                                      <input type="radio" name="rdbHasHadPublicPosition" value="0" v-model="hasHadPublicPosition" style="margin-left:20px"/>
                                      <label class="label-input-radio">NO</label><br>
                                  </div>
                                  <div v-show="hasHadPublicPosition == '1'">
                                      <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                          
                                          <div class="mt-5 text-left mr-10">
                                              <div class="tooltips">
                                                  <label class="label-input">¿Qué cargo público ha desempeñado?</label>
                                                  <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                                      minlength="4" maxlength="50" pattern="[a-zA-Z-0-9áéíóúñüÁÉÍÓÚÑÜ ]{4,50}" v-model="publicPosition">
                                                  <span id="tooltipstext" class="tooltipstext">Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual</span>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col w-full mt-5" style="padding:0px;">
                                          <label class="label-input">Periodo de :</label>
                                          <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                              <!-- <input type="text" id="datepickerStartDatePublicPosition" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="calculateNumberEntryTwoDates()"
                                              minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="startDatePublicPosition" v-on:click="establisDateMaxToday()"
                                              placeholder="Seleccionar fecha"> -->
                                              <date-picker
                                                simple
                                                color="#810042"
                                                placeholder="Fecha inicio"
                                                locale="es"
                                                input-format="DD/MM/YYYY"
                                                @change="selectDate($event,2)"
                                                v-model="startDatePublicPosition"
                                                id="startDatePublicPosition"
                                                :localeConfig="{
                                                  es: {
                                                    displayFormat: 'DD/MM/YYYY',
                                                      lang: {
                                                        year: 'Año',
                                                        month: 'Mes',
                                                        day: 'Día',
                                                        label: '',
                                                        submit: 'Seleccionar',
                                                        cancel: 'Cancelar',
                                                        now: 'Hoy',
                                                        nextMonth: 'Siguiente',
                                                        prevMonth: 'Anterior',
                                                      },
                                                    }
                                                }" />
                                          </div>
                                      </div>
                                      
                                      <div class="col w-full mt-5" style="padding-left:0px;">
                                          <label class="label-input">a:</label>
                                          <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                              <!-- <input type="text" id="datepickerEndDatePublicPosition" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="calculateNumberEntryTwoDates()"
                                              minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="endDatePublicPosition" v-on:click="establisDateMaxToday()"
                                                      placeholder="Seleccionar fecha"> -->
                                              <date-picker
                                                simple
                                                color="#810042"
                                                placeholder="Fecha fin"
                                                locale="es"
                                                input-format="DD/MM/YYYY"
                                                @change="selectDate($event,3)"
                                                v-model="endDatePublicPosition"
                                                id="endDatePublicPosition"
                                                :localeConfig="{
                                                  es: {
                                                    displayFormat: 'DD/MM/YYYY',
                                                      lang: {
                                                        year: 'Año',
                                                        month: 'Mes',
                                                        day: 'Día',
                                                        label: '',
                                                        submit: 'Seleccionar',
                                                        cancel: 'Cancelar',
                                                        now: 'Hoy',
                                                        nextMonth: 'Siguiente',
                                                        prevMonth: 'Anterior',
                                                      },
                                                    }
                                                }" />
                                          </div>
                                      </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="form-group">
                                    <label class="label-input">¿Algún familiar ha tenido cargo público?</label> <br />
                                    <input type="radio" name="rdbHasFamilyHadPublicPosition" value="1" v-model="hasFamilyHadPublicPosition" required style="margin-left:30px;"/> 
                                    <label class="label-input-radio">SI</label>
                                    <input type="radio" name="rdbHasFamilyHadPublicPosition" value="0" v-model="hasFamilyHadPublicPosition" style="margin-left:20px"/> 
                                    <label class="label-input-radio">NO</label> <br>
                                  </div>
                                  <div v-show="hasFamilyHadPublicPosition == '1'">
                                    <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                      <div class="mt-5 text-left mr-10">
                                        <div class="tooltips">
                                          <label class="label-input">¿Qué cargo público ha desempeñado?</label>
                                          <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor"
                                              minlength="4" maxlength="50" pattern="[a-zA-Z-0-9áéíóúñüÁÉÍÓÚÑÜ ]{4,50}" v-model="publicPositionFamily">
                                          <span id="tooltipstext" class="tooltipstext">Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col w-full mt-5" style="padding:0px;">
                                        <label class="label-input">Periodo de :</label>
                                        <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                            <!-- <input type="text" id="datepickerStartDatePublicPositionFamily" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor" v-on:change="calculateNumberEntryTwoDates()"
                                            minlength="2" maxlength="30" readonly="readonly" pattern="[a-zA-Z-0-9éñúóáÑÁÉÓÚ]{2,30}" v-model="startDatePublicPositionFamily" v-on:click="establisDateMaxToday()"
                                            placeholder="Seleccionar fecha"> -->
                                            <date-picker
                                              simple
                                              color="#810042"
                                              placeholder="Fecha inicio"
                                              locale="es"
                                              input-format="DD/MM/YYYY"
                                              @change="selectDate($event,4)"
                                              v-model="startDatePublicPositionFamily"
                                              id="startDatePublicPositionFamily"
                                              :localeConfig="{
                                                es: {
                                                  displayFormat: 'DD/MM/YYYY',
                                                    lang: {
                                                      year: 'Año',
                                                      month: 'Mes',
                                                      day: 'Día',
                                                      label: '',
                                                      submit: 'Seleccionar',
                                                      cancel: 'Cancelar',
                                                      now: 'Hoy',
                                                      nextMonth: 'Siguiente',
                                                      prevMonth: 'Anterior',
                                                    },
                                                  }
                                              }" />
                                        </div>
                                    </div>
                                    
                                    <div class="col w-full mt-5" style="padding-left:0px;">
                                        <label class="label-input">a:</label>
                                        <div class="form-group" style="text-align: left;margin-top:-15px; margin-top:-2px;margin-right:10px">
                                            
                                            <date-picker
                                              simple
                                              color="#810042"
                                              placeholder="Fecha fin"
                                              locale="es"
                                              input-format="DD/MM/YYYY"
                                              @change="selectDate($event,5)"
                                              v-model="endDatePublicPositionFamily"
                                              id="endDatePublicPositionFamily"
                                              :localeConfig="{
                                                es: {
                                                  displayFormat: 'DD/MM/YYYY',
                                                    lang: {
                                                      year: 'Año',
                                                      month: 'Mes',
                                                      day: 'Día',
                                                      label: '',
                                                      submit: 'Seleccionar',
                                                      cancel: 'Cancelar',
                                                      now: 'Hoy',
                                                      nextMonth: 'Siguiente',
                                                      prevMonth: 'Anterior',
                                                    },
                                                  }
                                              }" />
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <br>
                    <br>
                    <div style="display:flex;justify-content:center">
                      <div>
                        <button class="next_button" type="submit" id="" >Siguiente</button>
                      </div>
                    </div>
                    <div style="display:flex;justify-content:center; margin-top:10px">
                      <div>
                        <button class="back_button" v-on:click="goToBack()" type="button" id="" >Atrás</button>
                      </div>
                    </div>
                    <br>
                    <br>
                </form>
            </div>
        </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>

<script>
import('@/assets/css/stylesheet.css')
import MenuLateral from "@/components/Cuenta/MenuLateral.vue";
import Loading from "@/components/Loading/VueLoading.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { saveRequest,getAdmissionRequest,updateOnlyStatus } from "@/api/user";
import { mask, maska } from 'maska';
import {  getTokenDecoden } from "@/helpers/tokenauth";
import {useRouter} from "vue-router";
export default {
  data() {
    return {
      errors: [],
      list_colonys: [],
      personId: null,
      postalCode: "",
      numberExt: "",
      numberInt: "",
      cellphone: "",
      phoneExt: "",
      keyCt: "",
      colony: "",
      municipality: "",
      state: "",
      city: "",
      presupustalCode: "",
      street: "",
      admissionDate: "",
      hasHadPublicPosition: "",
      hasFamilyHadPublicPosition: "",
      publicPosition: "",
      startDatePublicPosition: "",
      endDatePublicPosition: "",
      publicPositionFamily: "",
      startDatePublicPositionFamily: "",
      endDatePublicPositionFamily: "",
      category: "",
      status: "",
      occupation: "",
      occupation_list: [],
      labour_old: 0,
      nameWork: "",
      member_id: "",
      maxDate: "",
      minYearDatePicker: "",
      acceptConditions: null,
      notice_of_privacy_type: "",
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi: process.env.VUE_APP_CCAPI,
      user_id:null,
      admission_request_id:null,
      admission_status:null,
      mainOccupation: null,
      businessName: "",
      supervisor: "",
      is_retired: true,
      is_employee: false,
      is_job_owner: false,
      is_education_worker: false,
      is_student: false
    };
  },
  setup(){
    const router = useRouter();
    return { router }
  },
  name: "Informacion-Laboral-cliente-regular",
  components: {
    MenuLateral,
    Loading,
  },
  props: {
    msg: String,
  },
  directives: { maska },
  async mounted() {
    await this.loadInfo();
    this.establisDateMaxToday();
    this.establishYearMinDatePicker();
    this.getCatalogOccupation(false);
    this.validateNumberPhone();
  },
  watch : {
        street() {
          if (/[^a-zA-Z-0-9éñúóáÑÁÉÓÚíÍ ]/.test(this.street)) {
            this.street = this.street.slice(0, this.street.length-1);
          }
        },
        hasHadPublicPosition () {
            if (this.hasHadPublicPosition == "0") {
                this.publicPosition =""
                this.startDatePublicPosition =""
                this.endDatePublicPosition =""
            }
        },
        phoneExt (curr, prev) {
          if(curr.toString().length > 3){
            this.phoneExt = prev;
          }  
        },
        postalCode (curr, prev) {
          if(curr.toString().length > 5){
            this.postalCode = prev;
          }  
        },
        numberExt (curr, prev) {
          if(curr.toString().length > 5){
            this.numberExt = prev;
          }  
        },
    },
  methods: {
    changeNameWork() {
      this.businessName = "";
    },
    changeStatus() {
      this.clearActiveStatusFields();
    },
    clearActiveStatusFields() {
      this.mainOccupation = null;
      this.nameWork = "";
      this.presupustalCode = "";
      this.occupation = "";
      this.supervisor = "";
      this.cellphone = "";
      this.phoneExt = "";
      this.admissionDate = "";
      this.postalCode = "";
      this.numberExt = "";
      this.street = "";
      this.colony = "";
      this.municipality = "";
      this.city = "";
      this.state = "";
    },
  
    async validateAdmissionDate(){
      var auth = getTokenDecoden();
      var year = auth.obj.person.rfc.substring(4, 6)
      var month = auth.obj.person.rfc.substring(6, 8)
      var day = auth.obj.person.rfc.substring(8, 10)
      var ListAdmissionDate = this.admissionDate.split('-')

      console.log(ListAdmissionDate)
      
      var dateAdmission = new Date(ListAdmissionDate[1] + "-" + ListAdmissionDate[0] + "-" + ListAdmissionDate[2])
      var birtDay = new Date(year + "-" + month + "-" + day)
      birtDay.setFullYear(birtDay.getFullYear() + 18)

      var response = true;
      if(birtDay > dateAdmission){
        this.isLoading = false;
        await Swal.fire({
          title: "Aviso",
          text: "Fecha ingreso debe ser mayor a la mayoria de edad del socio",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        response = false;
      }
      return response;
    },
    validateSpecialCharacters(modelVar) {
      let replace = /[^a-zA-ZÀ-ÿ-0-9.-\s]+/;
      var regex = /^[a-zA-ZÀ-ÿ-0-9.-\s]+$/

      if (!regex.test(this[modelVar])) {
        console.log("invalido");
        this[modelVar] = this[modelVar].toString().replace(replace, '')
      }
      
    },
    async validateOnlyLettersAndNumbers(name, accept_spaces = false){
      var input = document.getElementById(name);
      let replace = /[^a-zA-ZÀ-ÿ-0-9]+/;
      var regex = /^[a-zA-ZÀ-ÿ-0-9]+$/

      if(accept_spaces){
        regex = /^[a-zA-ZÀ-ÿ-0-9\s]+$/;
        replace = /[^a-zA-ZÀ-ÿ-0-9@\s]+/;
      }else{
        input.value = input.value.toString().trim();
      }
      
      var result = regex.test(input.value);
      input.oninvalid = input.setCustomValidity('');

      if(!result && typeof result != null){
        input.value = input.value.toString().replace(replace, '');
        if(accept_spaces){
          input.oninvalid = input.setCustomValidity('Favor de introducir solo numeros, letras y espacios');
        }
        else{
          input.oninvalid = input.setCustomValidity('Favor de introducir solo numeros y letras');
        }
      }
    },
    async validateOnlyLetters(name, accept_spaces = false){
      var input = document.getElementById(name);
      var regex = /^([a-zA-ZéñúóáÑÁÉÓÚíÍ])*$/
      if(accept_spaces){
        regex = /^([a-zA-ZéñúóáÑÁÉÓÚíÍ\s])*$/
      }
      var result = regex.test(input.value)
      input.oninvalid = input.setCustomValidity('');
      if(!result){
        input.value = input.value.toString().slice(0, -1)
        if(accept_spaces){
          input.oninvalid = input.setCustomValidity('Favor de introducir solo letras y espacios');
        }
        else{
          input.oninvalid = input.setCustomValidity('Favor de introducir solo letras');
        }
        
      }
    },
    async validateNumberPhone(){
      var input = document.getElementById('cellphone');

      if(input == null)
        return;

      input.oninvalid = input.setCustomValidity('');
      if(input.value.length > 0 && input.value.length < 12){
        input.oninvalid = input.setCustomValidity('Debe ingresar los 10 dígitos del número');
      }
      
      if(input.value.length > 0 && input.value.length == 12 && (input.value[0] == "0" || input.value.substr(0,3) == "000")){
        input.oninvalid = input.setCustomValidity('El número es inválido');
      }
    },
    getCatalogOccupation(changeOfOccupation = true){
      if(changeOfOccupation){
        this.businessName=""
        this.presupustalCode=""
        this.supervisor=""
        this.occupation=""
        this.nameWork=""
        this.category=""
      }

      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      var client_type = json.data.response.admission_request.admission_information.client_classification

      if(this.mainOccupation == "Trabajador de la educacion"){
        client_type = "educator"
      }
      if(this.mainOccupation == "Empleado"){
        client_type = "cabsa_employee"
      }
      var hash_occupations = {
        token_auth: sessionStorage.getItem("login"),
        client_type: client_type
      }
      if(["cabsa_employee","educator"].includes(client_type)){
        this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_catalogs_occupation_by_client_type`, {
          params:hash_occupations
        },
        { 'Content-Type': 'application/json' })
        .then(response => {
          if(response.data.response.lista_ocupaciones.length == 0){
            alert("No se encontraron ocupaciones por el tipo de cliente, favor de contactar a sistemas.")
          }
          this.occupation_list = response.data.response.lista_ocupaciones
        }).catch(error => {
          alert("Ocurrio un error inesperado al consultar ocupaciones, favor de contactar a sistemas.")
        });
      }
    },
    removeMask(phone) {
      if (phone.includes("(")) {
        phone = phone.replace("(", "");
      }
      if (phone.includes(")")) {
        phone = phone.replace(")", "");
      }
      if (phone.includes("-")) {
        phone = phone.replace("-", "");
      }
      if (phone.includes(" ")) {
        phone = phone.replace(" ", "");
      }
      return phone;
    },
    validateAlphanumericValue(value) {
      if (!/\D/.test(value.replace(/ /g, "").replace(".", ""))) {
        return true;
      }
      return false;
    },
    conditions(value) {
      this.acceptConditions = value;
    },
    validateCP() {
            if (this.postalCode != "") {
                if (this.postalCode != null && this.postalCode.toString().length == 5) {
                    this.axios.post(this.multiApi + "/Api/Catalogo/Sepomex/obtener_colonias", {
                        contenido: {
                            codigo_postal : this.postalCode.toString(),
                            colonia : ""
                        },
                    },
                    {
                        auth: {
                            username: "multicr",
                            password: "*cabsa2018*",
                        },
                    },
                    { 'Content-Type': 'application/json' })
                    .then(response => {
                        if (response.data.estado.nombre == "") {
                            Swal.fire({
                                title: "Aviso",
                                text: "Introduzca un código postal valido",
                                icon: "warning",
                                confirmButtonColor: '#FEB72B',
                            });
                        }else{
                          this.list_colonys = response.data.colonias
                          this.municipality = response.data.municipio.nombre != null ? response.data.municipio.nombre : ""
                          this.state = response.data.estado.nombre != null ? response.data.estado.nombre : ""

                          if(response.data.ciudad.nombre != null){
                            this.city = response.data.ciudad.nombre
                          }
                          else{
                            this.city = "N/A"
                          }
                        }
                    }).catch(error => {
                      Swal.fire({
                        title: "Aviso",
                        text: "Introduzca un código postal valido",
                        icon: "warning",
                        confirmButtonColor: '#FEB72B',
                      });
                      
                      this.list_colonys = []
                      this.municipality = ""
                      this.state = ""
                      this.city = ""
                    });
                }else{
                  this.list_colonys = [];
                  this.municipality = ""
                  this.state = "";
                  this.city = "";
                }
            }
        },
        calculateNumberEntryTwoDates(){
            var dateToday = new Date()
            var dateAdminision = new Date(this.orderDateByYearMonthDay(this.admissionDate, '-'))

            var antiguedadd = dateToday.getFullYear() - dateAdminision.getFullYear()

            dateAdminision.setFullYear(dateAdminision.getFullYear() + antiguedadd)

            if(dateAdminision > dateToday){
                antiguedadd--
            }
            return antiguedadd
        },
        establisDateMaxToday(){
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth()+1; //January is 0!
            var YYYY = today.getFullYear();
            if(dd<10){
                dd='0'+dd
            } 
            if(mm<10){
                mm='0'+mm
            } 

            today = YYYY+'-'+mm+'-'+dd + 'T10:00:00.000';
            this.maxDate = today;
        },
        establishYearMinDatePicker() {
            var today = new Date();
            var YYYY = today.getFullYear()-100;

            this.minYearDatePicker = YYYY;
        },
        addUpperCase(value){
            if(value != ""){
                value = value.toString().toUpperCase();
            }
            return value
        },
        orderDateByDayMonthYear(date, typeSplit){
          if (date != "" && date != undefined) {
            var elemtsOfDate = date.split(typeSplit)
            var year = elemtsOfDate[0]
            var month = elemtsOfDate[1]
            var day = elemtsOfDate[2]
            return day+"-"+month+"-"+year
          }

          return "";
        },
        orderDateByYearMonthDay(date, typeSplit){
            return moment(date.split(' ')[0].split(typeSplit).reverse().join("-")).format("YYYY-MM-DD");
        },
        formatDate(date) {
            var d = new Date(date)
            var month = '' + (d.getMonth() + 1)
            var day = '' + d.getDate()
            var year = d.getFullYear()

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [day, month, year].join('/');
        },
        validateEmptyDate(){
            let list_erros = [];
            if (this.admissionDate == "" || this.admissionDate == null) {
                list_erros.push("Fecha de ingreso");
            }

      if (this.hasHadPublicPosition == "1") {
        if (
          this.startDatePublicPosition == "" ||
          this.startDatePublicPosition == null ||
          this.endDatePublicPosition == "" ||
          this.endDatePublicPosition == null
        ) {
          list_erros.push("Rango de fechas del Cargo Público");
        }
      }

      return list_erros;
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.user_id = auth["id"];
      this.personId = auth.obj.person["id"];
      await this.searchPersonalData();
      //await this.getAdmissionRequest();
    },
    async searchPersonalData() {
      let response = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      if(Object.entries(response).length != 0)
      {
        await this.getDataAdmissionUser(response);
      }else {
        await this.getAdmissionRequest();
      }
    },
    async getDataAdmissionUser(response) {
      if(Object.entries(response).length != 0) {
        this.admission_request_id = response.data.response.admission_request.id;
        this.admission_status = response.data.response.admission_request.status;  //se obtiene el estatus de la solicitud
        
        this.is_retired = response.data.response.admission_request.admission_information.work_info_attributes.laboral_status == "JUBILADO" ? false : true;
        
        if(response.data.response.admission_request.admission_information.work_info_attributes.laboral_status == "ESTUDIANTE"){
          this.is_student = true;
          this.is_retired = false;
        }
        
        if(response.data.response.admission_request.admission_information.workplace_attributes.status != "")
        {
          this.postalCode = response.data.response.admission_request.admission_information.workplace_attributes.postal_code;
          this.keyCt =
            response.data.response.admission_request.admission_information.workplace_attributes.center_key;
          this.validateCP();
          this.colony =
            response.data.response.admission_request.admission_information.workplace_attributes.suburb;
          this.numberExt =
            response.data.response.admission_request.admission_information.workplace_attributes.ext_number;
          this.municipality =
            response.data.response.admission_request.admission_information.workplace_attributes.municipality;
          this.state = response.data.response.admission_request.admission_information.workplace_attributes.state;
          this.city = response.data.response.admission_request.admission_information.workplace_attributes.city;
          this.presupustalCode =
            response.data.response.admission_request.admission_information.work_info_attributes.employee_number.length > 0
            ? response.data.response.admission_request.admission_information.work_info_attributes.employee_number[0]
            : "";
          this.street =
            response.data.response.admission_request.admission_information.workplace_attributes.street;
          this.admissionDate = this.orderDateByDayMonthYear(
            response.data.response.admission_request.admission_information.work_info_attributes.active_since,
            "-"
          );
          document.getElementById('vpd-admissionDate').value = this.admissionDate.replaceAll("-","/")

          this.category =
            response.data.response.admission_request.admission_information.work_info_attributes.category;
          this.status =
            response.data.response.admission_request.admission_information.work_info_attributes.laboral_status;
            console.log("estatus 2:",this.status);
          this.numberExt =
            response.data.response.admission_request.admission_information.workplace_attributes.ext_number;
          this.occupation =
            response.data.response.admission_request.admission_information.work_info_attributes.occupation;
          this.labour_old = this.calculateNumberEntryTwoDates();

          if(response.data.response.admission_request.admission_information.public_office_attributes["0"].relationship != "")
          {
            let public_office_attributes_array = this.convertListToArray(response.data.response.admission_request.admission_information.public_office_attributes);

            this.hasHadPublicPosition =
            public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].has_had_public_positions == true ? 1 : 0;

            this.publicPosition =
            public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].positions_name;

            this.startDatePublicPosition = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].start_date_public_positions,
            "-");
            document.getElementById('vpd-startDatePublicPosition').value = this.startDatePublicPosition.replaceAll("-","/")

            this.endDatePublicPosition = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'titular')[0].end_date_public_positions,
            "-");
            document.getElementById('vpd-endDatePublicPosition').value = this.endDatePublicPosition.replaceAll("-","/")

            /*Familiar*/

            this.hasFamilyHadPublicPosition =
            public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].has_had_public_positions == true ? 1 : 0;

            this.publicPositionFamily =
            public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].positions_name;

            this.startDatePublicPositionFamily = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].start_date_public_positions,
            "-");
            document.getElementById('vpd-startDatePublicPositionFamily').value = this.startDatePublicPositionFamily.replaceAll("-","/")
            
            this.endDatePublicPositionFamily = this.orderDateByDayMonthYear(
            public_office_attributes_array.filter(({relationship}) => relationship === 'familiar')[0].end_date_public_positions,
            "-");
            document.getElementById('vpd-endDatePublicPositionFamily').value = this.endDatePublicPositionFamily.replaceAll("-","/")
          }

          this.mainOccupation = response.data.response.admission_request.admission_information.work_info_attributes.principal_occupation;
          this.businessName = response.data.response.admission_request.admission_information.work_info_attributes.business_name;
          this.supervisor = response.data.response.admission_request.admission_information.work_info_attributes.supervisor;

          this.nameWork =
            response.data.response.admission_request.admission_information.workplace_attributes.name;
          let contact_info =  response.data.response.admission_request.admission_information.workplace_attributes.contact_info_attributes;
          for (let contact in contact_info) {
            if(contact_info[contact].contact_type === "telefono") {
              this.cellphone = mask(contact_info[contact].value, '### ###-####');
              this.phoneExt = contact_info[contact].extension;
            }

            //this.mask = '### ###-####';
          }
        }
      }
    },
    async Next(e) {
      e.preventDefault();
      this.isLoading = true;

      if(!await this.validateAdmissionDate()){
        return;
      }

      if (!this.validateFields()) {
        this.isLoading = false;
        return;
      }

      var infoJson = this.buildJson();
     
      if(this.admissionStatusIsValidToSave(this.admission_status))
      {
        await this.saveRequest(infoJson);
      }
      this.router.push({path:"/cuenta/ahorro"});
    },
    buildJson(admission_status = "laboral_info") {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);

      this.labour_old = this.calculateNumberEntryTwoDates();
      
      json.data.response.admission_request.admission_information.work_info_attributes.laboral_status = this.addUpperCase(this.status);
      json.data.response.admission_request.admission_information.work_info_attributes.active_since = this.admissionDate == "" ? this.orderDateByYearMonthDay(this.getCurrentDate(), '-') : this.orderDateByYearMonthDay(this.admissionDate,"-");
      json.data.response.admission_request.admission_information.work_info_attributes.category = this.category == "" ? "-----" : this.addUpperCase(this.category);
      json.data.response.admission_request.admission_information.work_info_attributes.occupation = this.occupation == "" ? "-----" : this.occupation;
      json.data.response.admission_request.admission_information.work_info_attributes.status = "activo";

      json.data.response.admission_request.admission_information.work_info_attributes.employee_number = [this.addUpperCase(this.presupustalCode).trim()];
      json.data.response.admission_request.admission_information.work_info_attributes.principal_occupation = this.mainOccupation == null ? "-----" : this.mainOccupation;
      json.data.response.admission_request.admission_information.work_info_attributes.business_name = this.businessName.trim();
      json.data.response.admission_request.admission_information.work_info_attributes.supervisor = this.supervisor == "" ? "-----" : this.supervisor.trim();

      json.data.response.admission_request.admission_information.workplace_attributes.name = this.nameWork == "" ? "-----" : this.addUpperCase(this.nameWork);
      json.data.response.admission_request.admission_information.workplace_attributes.center_key = this.addUpperCase(this.keyCt);
      json.data.response.admission_request.admission_information.workplace_attributes.status = "activo";
      json.data.response.admission_request.admission_information.workplace_attributes.street = this.street == "" ? "-----" : this.addUpperCase(this.street).trim();
      json.data.response.admission_request.admission_information.workplace_attributes.between_street_one = "";
      json.data.response.admission_request.admission_information.workplace_attributes.between_street_two = "";
      json.data.response.admission_request.admission_information.workplace_attributes.ext_number = this.numberExt == "" ? "-----" : this.numberExt;
      json.data.response.admission_request.admission_information.workplace_attributes.int_number = "";
      json.data.response.admission_request.admission_information.workplace_attributes.suburb = this.colony == "" ? "-----" : this.addUpperCase(this.colony);
      json.data.response.admission_request.admission_information.workplace_attributes.postal_code = this.postalCode.toString() == "" ? "85000" : this.postalCode.toString();
      json.data.response.admission_request.admission_information.workplace_attributes.city = this.city == "" ? "-----" : this.addUpperCase(this.city);
      json.data.response.admission_request.admission_information.workplace_attributes.state = this.state == "" ? "-----" : this.addUpperCase(this.state);
      json.data.response.admission_request.admission_information.workplace_attributes.country = "MÉXICO";
      json.data.response.admission_request.admission_information.workplace_attributes.municipality = this.municipality == "" ? "-----" : this.addUpperCase(this.municipality);

      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.street = this.street == "" ? "-----" : this.addUpperCase(this.street).trim();
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.between_street_one = "";
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.between_street_two = "";
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.ext_number = this.numberExt == "" ? "-----" : this.numberExt;
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.int_number = "";
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.suburb = this.colony == "" ? "-----" : this.addUpperCase(this.colony);
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.postal_code = this.postalCode.toString() == "" ? "85000" : this.postalCode.toString();
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.city = this.city == "" ? "-----" : this.addUpperCase(this.city);
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.state = this.state == "" ? "-----" : this.addUpperCase(this.state);
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.country = "MÉXICO";
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.status = "activo";
      json.data.response.admission_request.admission_information.workplace_attributes.adress_attributes.municipality = this.municipality == "" ? "-----" : this.addUpperCase(this.municipality);

      json.data.response.admission_request.admission_information.workplace_attributes.contact_info_attributes = this.insertContactInfo();

      if(json.data.response.admission_request.admission_information.work_info_attributes.principal_occupation == "Trabajador de la educacion"){
        json.data.response.admission_request.admission_information.client_classification = "educator"
      }
      if(json.data.response.admission_request.admission_information.work_info_attributes.principal_occupation == "Negocio Propio"){
        json.data.response.admission_request.admission_information.client_classification = "businessman"
      }
      if(json.data.response.admission_request.admission_information.work_info_attributes.principal_occupation == "Empleado" && json.data.response.admission_request.admission_information.workplace_attributes.name == "OTRO"){
        json.data.response.admission_request.admission_information.client_classification = ""
      }
      if(json.data.response.admission_request.admission_information.work_info_attributes.principal_occupation == "Empleado" && json.data.response.admission_request.admission_information.workplace_attributes.name == "GRUPO CABSA"){
        json.data.response.admission_request.admission_information.client_classification = "cabsa_employee"
      }

      if(!this.is_retired && this.is_student == false){
        json.data.response.admission_request.admission_information.client_classification = "retired"
      }

      if(!this.is_retired && this.is_student == true){
        json.data.response.admission_request.admission_information.client_classification = "student"
      }

      console.log("client_classification:", json.data.response.admission_request.admission_information.client_classification)

      moment.locale("es");

      let titular = {
        has_had_public_positions: this.hasHadPublicPosition == 1 ? true : false,
        positions_name: this.addUpperCase(this.publicPosition).trim(),
        start_date_public_positions: this.hasHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.startDatePublicPosition, "-") : "",
        end_date_public_positions: this.hasHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.endDatePublicPosition, "-"): "",
        relationship: "titular",
      }

      let familiar = {
        has_had_public_positions: this.hasFamilyHadPublicPosition == 1 ? true : false,
        positions_name: this.addUpperCase(this.publicPositionFamily).trim(),
        start_date_public_positions: this.hasFamilyHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.startDatePublicPositionFamily, "-") : "",
        end_date_public_positions: this.hasFamilyHadPublicPosition == 1 ? this.orderDateByYearMonthDay(this.endDatePublicPositionFamily, "-") : "",
        relationship: "familiar",
      }

      let public_office_attributes_array = [];
      public_office_attributes_array.push(titular);
      public_office_attributes_array.push(familiar);

      json.data.response.admission_request.admission_information.public_office_attributes = Object.assign({}, public_office_attributes_array);

      this.setPersonalInfoToLocalStorage('admissionUser'+this.user_id, json)

      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_request_id,
        user_id: this.user_id,
        change_for: admission_status,
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    async getAdmissionRequest(){
      let token = sessionStorage.getItem("login");
      let person_id = this.personId;
      await getAdmissionRequest(
        token,
        person_id
      )
      .then((response) => {
        this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
        this.getDataAdmissionUser(response);
      })
      .catch((error) => {
        Swal.fire({
          title: "Aviso",
          text:
            "Se presentó un error. No fue posible obtener los datos de la solicitud.",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        // window.location.href = "/cuenta/registro-datos-personales";
        this.router.push({path:"/cuenta/registro-datos-personales"});
      });
    },
    async saveRequest(jsonData){
      this.isLoading=true;
      jsonData.token_auth = sessionStorage.getItem("login");
      await saveRequest(
        jsonData
      )
        .then((response ) => {
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
          console.log("Solicitud guardada con éxito: ");
        })
        .catch((error) => {
          console.log(
            "Se presento un error al guardar la información laboral: ",
            error
          );
        }).finally( () => {this.isLoading=false})
    },
    insertContactInfo() {
      let contact_array = [];
      let contact_obj = {};

      let telefono = {
        value: this.cellphone == "" ? "0000000000" : this.removeMask(this.cellphone),
        status:"activo",
        contact_type:"telefono",
        extension: this.phoneExt == "" ? "000" : this.phoneExt
      }

      contact_array.push(telefono);
      contact_obj = Object.assign({}, contact_array)

      return contact_obj;
    },
    validateFields() {

      if(this.postalCode.toString().length != 5 && this.status == "ACTIVO"){        
        Swal.fire({
          title: "Aviso",
          text:
            "Favor de capturar un código postal valido",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return false;
      }

      if (this.admissionDate == "" && this.is_retired != false) {
        Swal.fire({
          title: "Aviso",
          text:
            "Favor de capturar la Fecha de Ingreso",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return false;
      }
        
      if (this.hasHadPublicPosition == "1") {
        if (this.publicPosition != "") {
          if (this.validateAlphanumericValue(this.publicPosition)) {
            Swal.fire({
              title: "Aviso",
              text: "El cargo público no puede contener sólo numeros.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            return false;
          }
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Ingrese el cargo público que ha desempeñado el titular.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      if (this.hasHadPublicPosition == "1") {
        moment.locale("es");
        if (this.startDatePublicPosition == "" || this.endDatePublicPosition == "") {
          Swal.fire({
            title: "Aviso",
            text:
              "Favor de capturar las fechas del cargo público del titular",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
        let dateInitial = moment(this.startDatePublicPosition,["DD-MM-YYYY", "YYYY-MM-DD"]);
        let dateFinal = moment(this.endDatePublicPosition, ["DD-MM-YYYY", "YYYY-MM-DD"]);
        if (dateInitial >= dateFinal) {
          Swal.fire({
            title: "Aviso",
            text:
              "La fecha final del cargo público no puede ser menor a la fecha inicial. Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      if (this.hasFamilyHadPublicPosition == "1") {
        if (this.publicPositionFamily != "") {
          if (this.validateAlphanumericValue(this.publicPositionFamily)) {
            Swal.fire({
              title: "Aviso",
              text: "El cargo público del familiar no puede contener sólo numeros.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            return false;
          }
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Ingrese el cargo público que ha desempeñado el familiar.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }

      if (this.hasFamilyHadPublicPosition == "1") {
        moment.locale("es");
        if (this.startDatePublicPositionFamily == "" || this.endDatePublicPositionFamily == "") {
          Swal.fire({
            title: "Aviso",
            text:
              "Favor de capturar las fechas del cargo público del familiar",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
        let dateInitialFamiliar = moment(this.startDatePublicPositionFamily,["DD-MM-YYYY", "YYYY-MM-DD"]);
        let dateFinalFamiliar = moment(this.endDatePublicPositionFamily, ["DD-MM-YYYY", "YYYY-MM-DD"]);
        if (dateInitialFamiliar >= dateFinalFamiliar) {
          Swal.fire({
            title: "Aviso",
            text:
              "La fecha final del cargo público familiar no puede ser menor a la fecha inicial. Si actualmente está desempeñando un cargo público se deberá capturar la fecha actual.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      }
      return true;
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    convertListToArray(list) {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    },
    getCurrentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var YYYY = today.getFullYear();

      today = dd + '-' + mm + '-' + YYYY;
      return today;
    },
    async goToBack() {
      await updateOnlyStatus(sessionStorage.getItem("login"), this.admission_request_id, "update_economical_info").then(() => {
        this.$router.push({path:"/cuenta/informacion-economica"});
      });
    },
    admissionStatusIsValidToSave( status ) {
      const validStatuses = [
        "autorizo_descuento_aceptado",
        "video_completo",
        "asignada",
        "en_revision",
        "rechazada",
        "cancelada"
      ];

      return (validStatuses.find(value => value == status))? false: true;
    },
    selectDate(event,op){
      switch(op){
        case 1:
           if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
             Swal.fire({
              title: "Aviso",
              text:
                "La fecha de ingreso tiene que ser menor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.admissionDate =null
            return
          }else{
            this.admissionDate = moment(event._d).format("DD-MM-YYYY")
          }
          document.getElementById('vpd-admissionDate').value = this.admissionDate
          
        break;
        case 2:
          if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
              Swal.fire({
              title: "Aviso",
              text:
                "La fecha de inicio de cargo público tiene que ser menor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
              this.startDatePublicPosition=null
              return
          } else{
            this.startDatePublicPosition = moment(event._d).format("DD-MM-YYYY")
          }
          
        break;
        case 3:
        
           if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
            Swal.fire({
              title: "Aviso",
              text:
                "La fecha de final de cargo público tiene que ser mayor a del inicio.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.endDatePublicPosition=null
            return
          } else{
            this.endDatePublicPosition = moment(event._d).format("DD-MM-YYYY")
          }
        break;
        case 4:

           if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
            Swal.fire({
              title: "Aviso",
              text:
                "La fecha de inicio de familiar con cargo público tiene que ser menor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.startDatePublicPositionFamily=null
            return
          } else{
            this.startDatePublicPositionFamily = moment(event._d).format("DD-MM-YYYY")
          }

        break;
        case 5:

           if((moment(event._d).format("YYYY-MM-DD"))> (moment().format("YYYY-MM-DD"))){
            Swal.fire({
              title: "Aviso",
              text:
                "La fecha final de familiar con cargo público tiene que ser mayor a la actual.",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
            this.endDatePublicPositionFamily=null
            return
          } else{
            this.endDatePublicPositionFamily = moment(event._d).format("DD-MM-YYYY")
          }

        break; 
      }
    },
  }
};
</script>

<style>
.activeClass {
  @apply bg-gray-600;
}

.public-charge {
  list-style-type: none;
  margin: 25px 0 0 0;
  padding: 0;
}

.public-charge li {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.public-charge label,
.public-charge input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.public-charge input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.public-charge input[type="radio"]:checked+label,
.Checked+label {
  background: yellow;
}

.public-charge label {
  padding: 5px;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
}

.public-charge label:hover {
  background: #DDD;
}

.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
    .collapse.dont-collapse-sm {
        display: block;
        height: auto !important;
        visibility: visible;
    }
    .border{
        border-right: 1px dashed #333;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}

@media (max-width: 766px) {
    .border{
        margin-left: 9px;
        margin-right: -15px;
        border-right: 1px dashed #ffffff;
        border-top: 1px dashed #ffffff;
        border-left: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;
    }
}


#button_popups{
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

@media (max-width: 500px) {
  #button_popups {
    height: 50px !important;
  }
}

/*Tooltip*/

.tooltips{
  position: relative;
  /* display: inline-block; */
  opacity: 1;
}

.tooltips .tooltipstext {
  visibility: hidden;
  width: 140px;
  background-color: #82385b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -18px;
  left: 105%;
}

.tooltips .tooltipstext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #82385b transparent transparent;
}

.tooltips input:focus + .tooltipstext{
    visibility: visible;
}

@media only screen and (max-width: 1406px) {
  .tooltips .tooltipstext {
    visibility: hidden;
    width: 210px;
    height: 100px;
    background-color: #82385b;
    font-size: 15px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    z-index: 1;
    top: -100px;
    bottom: 80%;
    left: 85%;
    margin-left: -60px;
  }
  .tooltips .tooltipstext::after {
    content: "";
    position: absolute;
    top:105%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #82385b transparent transparent transparent;
  }
}
@media only screen and (max-width: 1281px) {
  .tooltips .tooltipstext {
    left: 80%;
  }
}

@media only screen and (max-width: 1279px) {
  .tooltips .tooltipstext {
    left: 100%;
  }
}

@media only screen and (max-width: 1234px) {
  .tooltips .tooltipstext {
    left: 97%;
  }
}

@media only screen and (max-width: 1190px) {
  .tooltips .tooltipstext {
    left: 30%;
  }
}

.vpd-input-group input{
  border: 2px solid rgba(205, 205, 205, 0.416) !important;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  padding: 5px !important;
  box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important;
}
</style>
